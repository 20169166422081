import React from 'react';
import styles from './PopularSearchTerms.module.scss';
import { FormattedMessage } from 'react-intl';
import { getPagePath } from '../../utils/appRoutes';
import { ARD_SEARCH } from '../../utils/appRoutes.definitions';
import { Link, useLoaderData } from 'react-router-dom';
import classNames from 'classnames';
import { useConstructURL } from '../../utils/useConstructURL';

const PopularSearchTerms = ({ classList = {}, categoryPopularSearchTerms = [], categoriesInUrl = [] }) => {
  const constructURL = useConstructURL();
  const { popularSearchTerms } = useLoaderData() || {};

  if (!categoryPopularSearchTerms?.length && !popularSearchTerms?.length) return null;
  return (
    <div className={classList.root || styles.root}>
      <div className={classNames('container', classList.content || styles.content)}>
        <span>
          <FormattedMessage id="home.popularSearchTerms" defaultMessage="Popular search terms" />:
        </span>
        {categoryPopularSearchTerms?.map((term) => (
          <Link
            key={term}
            to={constructURL(`${getPagePath(ARD_SEARCH, categoriesInUrl)}?q=${term}`)}
            className={classList.term || styles.term}
          >
            {term.trim()}
          </Link>
        ))}
        {!categoryPopularSearchTerms?.length &&
          !!popularSearchTerms?.length &&
          popularSearchTerms.map((term) => (
            <Link
              key={term}
              to={`${getPagePath(ARD_SEARCH)}?q=${term.trim()}`}
              className={classList.term || styles.term}
            >
              {term.trim()}
            </Link>
          ))}
      </div>
    </div>
  );
};

export default PopularSearchTerms;
