import React from 'react';
import classnames from 'classnames';
import { FormattedMessage, FormattedNumberParts } from 'react-intl';
import { Link } from 'react-router-dom';

import { ReactComponent as CloseIcon } from '../../images/icons/close-icon-grey.svg';

import styles from './ActiveFilters.module.scss';
import { getPagePath } from '../../utils/appRoutes';
import { ARD_SEARCH } from '../../utils/appRoutes.definitions';
import { useConstructURL } from '../../utils/useConstructURL';

const ActiveFilters = ({ activeFilters, invertedColors, classList = {}, removeAll = true }) => {
  const constructURL = useConstructURL();
  const clearAllFiltersUrl = activeFilters.map(([key]) => `${key}=`).join('&');

  const filters = activeFilters
    .reduce((output, item) => {
      if (item[1].includes('|')) {
        return output.concat([...item[1].split('|').map((value) => [item[0], value])]);
      }

      return output.concat([item]);
    }, [])
    .map((entry, idx, arr) => {
      const [key, value] = entry;
      if (!key) return null;

      switch (key) {
        case 'priceFrom':
          const [priceToKey, priceToValue] = arr
            .find((item) => item[0] === 'priceTo')
            .map((value) => (value === 'undefined' ? undefined : value)); //FIXME: undefined in url params

          return (
            <li key={`activeFilters_${key}_${priceToKey}`}>
              <FormattedNumberParts style="currency" value="0" currency={process.env.REACT_APP_WEBSITE_CURRENCY}>
                {([currency]) => (
                  <Link to={constructURL('?priceTo=&priceFrom=')} className={styles.activeFilterPill}>
                    {priceToValue && (
                      <span className={styles.activeFilterPillText}>
                        {currency.value} {value}-{currency.value} {priceToValue}
                      </span>
                    )}
                    {!priceToValue && (
                      <span className={styles.activeFilterPillText}>
                        {currency.value} {value}+
                      </span>
                    )}
                    <span className={styles.activeFilterPillCross}>×</span>
                  </Link>
                )}
              </FormattedNumberParts>
            </li>
          );
        case 'priceTo':
          return null;
        default:
          const otherValues = activeFilters
            .find(([itemKey]) => key === itemKey)[1]
            .split('|')
            .filter((itemValue) => itemValue !== value)
            .join('|');
          return (
            <li key={`activeFilters_${key}_${idx}`}>
              <Link to={constructURL(`?${key}=${otherValues}`)} className={styles.activeFilterPill}>
                <span className={styles.activeFilterPillText}>{value}</span>
                <span className={styles.activeFilterPillCross}>×</span>
              </Link>
            </li>
          );
      }
    });
  return (
    <div className="row">
      <div
        className={classnames(
          invertedColors ? classList.rootInvertedColors || styles.rootInvertedColors : classList.root || styles.root,
          styles.activeFiltersContainer,
          'col-12-xs offset-2-xxl col-10-xxl'
        )}
      >
        <ul className={classnames(styles.activeFilters)}>{filters}</ul>
        {removeAll && (
          <div className={styles.clearAllFilters}>
            <Link
              className={styles.clearAllFiltersCross}
              to={constructURL(`${getPagePath(ARD_SEARCH)}?${clearAllFiltersUrl}`)}
            >
              <span className={classList.clearAllFiltersCrossIcon || styles.clearAllFiltersCrossIcon}>
                <CloseIcon />
              </span>
              <span className={classList.clearAllFiltersCrossText || styles.clearAllFiltersCrossText}>
                <FormattedMessage defaultMessage="Clear all" id="common.clearAllFilters" />
              </span>
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

ActiveFilters.displayName = 'ActiveFilters';

export default ActiveFilters;
