import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getPagePath } from '../../utils/appRoutes';
import { ARD_CATEGORY_SEARCH, ARD_SEARCH } from '../../utils/appRoutes.definitions';
import getCDNImage from '../../utils/getCDNImage';
import styles from './BrandDiscountCard.module.scss';
import { useConstructURL } from '../../utils/useConstructURL';

const BrandDiscountCard = ({ brandData = {}, categoryPath, offScreen }) => {
  const constructURL = useConstructURL();

  const parseDiscountsData = {
    homePage: ({ link, name, discount_max }) => ({
      brand: name,
      discount: Math.floor(discount_max),
      link: constructURL(`${getPagePath(ARD_SEARCH)}${link}`),
    }),
    categoriesPage: ([brand, discount], categoryPath) => ({
      brand,
      discount: Math.floor(discount),
      link: constructURL(`${getPagePath(ARD_CATEGORY_SEARCH, categoryPath)}?brand=${brand}&q=korting`),
    }),
  };

  const discountsDataParser = categoryPath ? parseDiscountsData.categoriesPage : parseDiscountsData.homePage;
  const { brand, link, discount } = discountsDataParser(brandData, categoryPath);
  const products = brandData?.pdps?.slice(0, 4) || [];
  return (
    <Link to={link} className={styles.root}>
      <div className={styles.discount}>
        <FormattedMessage
          id="category.shopDiscounts.discount"
          values={{ discount }}
          defaultMessage="up to {discount}%"
        />
      </div>
      <div className={styles.productContainer}>
        {products.map((product) => (
          <img
            key={product.id}
            className={styles.product}
            loading={offScreen ? 'lazy' : 'eager'}
            src={getCDNImage(product.image)}
            alt={product.name}
          />
        ))}
      </div>
      <div className={styles.brandFooter}>
        <div className={styles.brandInfo}>
          <div className={styles.brandName}>{brand}</div>
          {brandData?.products && (
            <div className={styles.brandItems}>
              {brandData.products} <FormattedMessage id="common.items" defaultMessage="items" />
            </div>
          )}
        </div>
        {brandData.logo && (
          <div
            className={styles.brandLogo}
            style={{
              backgroundImage: `url(${brandData.logo})`,
            }}
          />
        )}
      </div>
    </Link>
  );
};

export default BrandDiscountCard;
